<template>
    <div>
        <div class="card-table-body ifon-add-title-block m-0 p-0 pl-3 pr-3">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="caz-blocks-sarcho-title">
                        <div class="content-title d-flex align-center mr-2">
                           {{ $t('message.files') }}
                        </div>
                    
                    </div>
                </el-col>

                <el-col :span="12" class="flex-style text-right">
                    <el-button type="success" size="small"  @click="centerDialogVisible = true">Создать</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="m-3">
            <table 
            class="table-my-code table-bordered p-2 boder-left-none"
            :class="mode ? 'table__myday' : 'table__mynight'"
            >
                <thead>
                    <tr>
                        <th> {{ $t('message.file_name') }} </th>
                        <th> {{ $t('message.user') }} </th>
                        <th> {{ $t('message.created_at') }} </th>
                        <th style="width: 200px;"> {{ $t('message.download') }} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="pro_file in list">
                        <td> <span  @click="showImage(pro_file.file)" style="cursor: pointer;">{{ pro_file.file_name }}</span> </td>
                        <td> {{ pro_file.user ? pro_file.user.name : '' }} </td>
                        <td> {{ pro_file.created_at }} </td>
                        <td>
                            <el-button @click="downloadFile(pro_file.file)" type="success" size="small" icon="el-icon-download" circle></el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <el-dialog
            :title="$t('message.upload_file')"
            :visible.sync="centerDialogVisible"
            width="30%"
            center>
            <span>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item label="Имя файла" prop="file_name">
                        <el-input v-model="form.file_name"></el-input>
                    </el-form-item>
                    <div id="dropArea" @dragover.prevent @drop="handleFileDrop" @click="openFileInput" v-loading="isLoading">
                        <div v-if="!form.file" class="mm_dropArea_innner p-3">
                            <i class="fas fa-cloud-upload-alt fa-3x"></i>
                            <p> {{ $t('message.upload_file') }} </p> 
                        </div>
                    </div>
                </el-form>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button v-loading="loadingButton" type="primary" @click="submit()"> {{ $t('message.save') }}</el-button>
            </span>
        </el-dialog>

        <el-dialog
          v-if="selectedImage.name"
          :title="selectedImage.name"
          :append-to-body="true"
          width="85%"
          custom-class="mm-picture-show"
          :visible.sync="dialogPictureVisible">
          <div ref="ImageContainer" class="text-center w-100">
            <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(selectedImage.type || (/[^.]*$/.exec(selectedImage.name)[0])) ||
                (selectedImage.raw && ['image/png', 'image/jpeg'].includes(selectedImage.raw.type))"
              style="object-fit: contain;"
              class="p-1"
              :src="selectedImage.url"
              fit="fill">
            </el-image>
          </div>

        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    // name: 'GoCrmFrontedWorkMeetingDoc',

    data() {
        return {
            form:{
                file_extension: '',
                file_name: '',
                file: null,
                user_id: null,
                file_protocolable_type: "work_meetings",
                file_protocolable_id: this.$route.params.id,
            },
            filterForm: {
                file_protocolable_type: "work_meetings",
                file_protocolable_id: this.$route.params.id,
            },
            selectedImage: {},
            isLoading: false,
            loadingButton: false,
            centerDialogVisible: false,
            dialogPictureVisible: false,
        };
    },

    computed: {
        ...mapGetters({
            mode: "MODE",
            authUser: "auth/user",
            rules: "fileProtocol/rules",
            model: "fileProtocol/model",
            list: "fileProtocol/list",
        }),
    },
    async created() {
        await this.updateFiles(this.filterForm);
    },

    methods: {
        ...mapActions({
            updateFiles: "fileProtocol/index",
            save: "fileProtocol/store"
        }),
        async handleFileDrop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;

            if (files.length > 0) {
                this.isLoading = true; // Show the loader while uploading

                const file = files[0];
                try {
                    await this.handleFileUpload(file);
                    this.isLoading = false; // Hide the loader after uploading
                } catch (error) {
                    console.error("File upload error:", error);
                    this.isLoading = false; // Hide the loader in case of an error
                }
            }
        },
        handleFileUpload(file) {
            return new Promise((resolve, reject) => {
                const isImage = file.type.startsWith("image/");
                const dropArea = document.getElementById("dropArea");
                this.form.file = file;
                this.form.file.url = URL.createObjectURL(file);
                this.form.file_name = this.getFileNameWithoutExtension(file.name);
                this.form.file_extension = this.getFileExtension(file.name);
                if (isImage) {  
                    dropArea.style.backgroundImage = `url(${this.form.file.url})`;
                    resolve();
                } else {
                    dropArea.style.backgroundImage = `url(../img/all-files.jpg)`;
                    resolve(); // Resolve the promise for non-image files
                }
            });
        },
        openFileInput() {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.style.display = "none";
            fileInput.addEventListener("change", (event) => {
                const files = event.target.files;

                if (files.length > 0) {
                this.handleFileUpload(files[0]);
                }
            });
            document.body.appendChild(fileInput);
            fileInput.click();
            document.body.removeChild(fileInput);
        },
        getFileNameWithoutExtension(fileName) {
            const lastIndex = fileName.lastIndexOf('.');
            if (lastIndex !== -1) {
                return fileName.substring(0, lastIndex);
            } else {
                return fileName;
            }
        },
        getFileExtension(fileName) {
            const lastIndex = fileName.lastIndexOf('.');
            if (lastIndex !== -1) {
                return fileName.substring(lastIndex, fileName.length);
            } else {
                return fileName;
            }
        },
        submit(){
            this.form.user_id = this.authUser.id;
            this.form.file_name = this.form.file_name + this.form.file_extension;
            this.loadingButton = true;  
            let formData = new FormData();
            for (const key in this.form) {
                if (this.form[key]) {
                    formData.append(key, this.form[key]);
                } else {
                    formData.append(key, "");
                }
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.updateFiles(this.filterForm);
                            this.centerDialogVisible = false;
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },

        showImage(file){
            console.log(file, 'showImage');
            if(file.name){
                if(['image/png', 'image/jpeg', 'jpeg', 'jpg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0])) || (file.raw && ['image/png', 'image/jpeg'].includes(file.raw.type))){
                    this.selectedImage = file;
                    this.dialogPictureVisible = true;
                }else{
                    this.downloadFile(file);
                }
            }
        },
        downloadFile(file){
            let link = document.createElement('a');
            link.href = file.url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    },
};
</script>

<style lang="scss" scoped>
    .boder-left-none th, .boder-left-none td{
        border-left: none !important;
        border-right: none !important;
    }
    #dropArea {
        width: 100%;
        height: 200px;
        border: 2px dashed #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #555;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .mm_dropArea_innner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>